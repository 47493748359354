<template>
  <div class="operation-condition">
    <app-query-bar
      v-model="queryData"
      :queryConfig="queryConfig"
      @onSearch="fetchOperationConditions" />
    <app-export-bar
      @onExport="onExport"
      @onExportAll="onExportAll" />
    <div class="tip-text">
      注：操作“+”可展开查看门店，“-”可关闭查看门店。渠道展示“渠道名称、门店数量、门店活跃量”；门店展示“门店名称、设备数量、设备活跃量”。
    </div>
    <app-data-table
      row-key="channelOrStoreId"
      :load-data="fetchStoreOperationConditionsByChannel"
      :columns="OPERATION_CONDITION_COLUMNS"
      :data="operationConditions"
      v-model="queryData"
      @onPageConfigChange="fetchOperationConditions">
      <template
        slot="channelOrStoreName"
        slot-scope="{ row }">
        <span :class="getClassNameByIsStoreData(row)">
          {{ row.channelOrStoreName }}
        </span>
      </template>
      <template
        slot="StoreOrDeviceCount"
        slot-scope="{ row }">
        <span :class="getClassNameByIsStoreData(row)">
          {{ row.StoreOrDeviceCount }}
        </span>
      </template>
      <template
        slot="StoreOrDeviceActiveCount"
        slot-scope="{ row }">
        <span :class="getClassNameByIsStoreData(row)">
          {{ row.StoreOrDeviceActiveCount }}
        </span>
      </template>
    </app-data-table>
  </div>
</template>

<script>
import moment from 'moment';
import { OPERATION_CONDITION_COLUMNS } from './constants';
import ChannelManageApi from '@/pages/business/channel-manage/channel-manage.api';
import DataTransferUtil from '@/utils/data-transfer.util';
import StoreManageApi from '@/pages/business/store-manage/store-manage.api';
import OperationConditionApi from './operation-condition.api';

export default {
  name: 'operation-condition',
  computed: {
    queryConfig() {
      return [
        {
          fieldName: 'channelId',
          name: '渠道',
          type: 'select',
          options: this.queryConfigChannelOptions,
        },
        {
          fieldName: 'storeId',
          name: '门店',
          type: 'select',
          options: this.queryConfigStoreOptions,
        },
        {
          fieldName: 'dateRange',
          name: '时间',
          type: 'dateRange',
          width: '200px',
          options: {
            shortcuts: [
              {
                text: '最近一周',
                value() {
                  return [new Date(moment().subtract(6, 'days').format()), new Date()];
                },
              },
              {
                text: '本月',
                value() {
                  return [new Date(moment().startOf('months').format()), new Date()];
                },
              },
              {
                text: '最近一月',
                value() {
                  return [new Date(moment().subtract(1, 'months').add(1, 'days').format()), new Date()];
                },
              },
              {
                text: '最近三月',
                value() {
                  return [new Date(moment().subtract(3, 'months').add(1, 'days').format()), new Date()];
                },
              },
            ],
          },
        },
      ];
    },
  },
  data() {
    return {
      OPERATION_CONDITION_COLUMNS,
      queryData: {
        page: 1,
        limit: 10,
        count: 0,
        channelId: '',
        storeId: '',
        dateRange: [new Date(moment().subtract(1, 'months').add(1, 'days').format()), new Date()],
      },
      operationConditions: [],
      queryConfigChannelOptions: [],
      queryConfigStoreOptions: [],
      switchToStoreData: false,
    };
  },
  created() {
    this.fetchOperationConditions();
    this.fetchQueryChannelOptions();
    this.fetchQueryStoreOptions();
  },
  methods: {
    getClassNameByIsStoreData(row) {
      if (this.switchToStoreData || row.isChildren) {
        return 'highlight';
      }

      return '';
    },
    async fetchOperationConditions() {
      const {
        page, limit, channelId, storeId, dateRange,
      } = this.queryData;
      const [startDate, endDate] = dateRange;

      const {
        count = 0,
        results = [],
      } = await OperationConditionApi.fetchOperationConditions(
        page,
        limit,
        channelId,
        storeId,
        startDate,
        endDate,
      );
      this.switchToStoreData = !!storeId?.toString();
      this.operationConditions = results.map((item) => {
        if (!this.switchToStoreData) {
          return {
            ...item,
            channelOrStoreId: `channel${item.channel_id}store${item.id}`,
            channelOrStoreName: item.channel_name,
            StoreOrDeviceCount: item.store_ct,
            StoreOrDeviceActiveCount: item.store_active_ct,
            _loading: false,
            children: [],
          };
        }

        return {
          ...item,
          channelOrStoreId: `channel${item.channel_id}store${item.id}`,
          channelOrStoreName: item.store_name,
          StoreOrDeviceCount: item.product_ct,
          StoreOrDeviceActiveCount: item.product_active_ct,
        };
      });
      this.$set(this.queryData, 'count', count);
    },
    async fetchStoreOperationConditionsByChannel(row, callback) {
      const storeOperationConditions = await OperationConditionApi
        .fetchStoreOperationConditionsByChannel(row.id);
      const result = storeOperationConditions.map((item) => ({
        ...item,
        isChildren: true,
        channelOrStoreId: `parent${row.channel_id}-channel${item.channel_id}store${item.id}`,
        channelOrStoreName: item.store_name,
        StoreOrDeviceCount: item.product_ct,
        StoreOrDeviceActiveCount: item.product_active_ct,
      }));
      callback(result);
      if (!result || result.length === 0) {
        this.$delete(row, 'children');
        this.$delete(row, '_loading');
      }
    },
    async fetchQueryChannelOptions() {
      const options = await ChannelManageApi.getChannelsByChannelName();
      this.queryConfigChannelOptions = DataTransferUtil.originTransfer2Options(options);
    },
    async fetchQueryStoreOptions() {
      const options = await StoreManageApi.getStoresByStoreNameOrChannelId();
      this.queryConfigStoreOptions = DataTransferUtil.originTransfer2Options(options);
    },
    async onExport() {
      const key = await OperationConditionApi.export(this.queryData);
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    async  onExportAll() {
      const key = await OperationConditionApi.export();
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
  },
};
</script>

<style lang="less" scoped>
.highlight {
  color: @mainBlue;
}

.tip-text {
  color: red;
  margin-bottom: @spacingMD;
}
</style>
