import axios from '@/plugins/axios';

class OperationConditionApi {
  static fetchOperationConditions(
    page = 1,
    limit = 10,
    channelId = NaN,
    storeId = NaN,
    startDate = '',
    endDate = '',
  ) {
    return axios({
      url: '/api/stat/operate/channel/page',
      data: {
        page,
        limit,
        channel_id: channelId,
        store_id: storeId,
        start_date: startDate,
        end_date: endDate,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询运营数据列表',
        },
      },
    });
  }

  static fetchStoreOperationConditionsByChannel(channelId) {
    return axios({
      url: `/api/stat/operate/${channelId}/store/data`,
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询运营门店数据列表',
        },
      },
    });
  }

  static export({ ...data }) {
    return axios({
      url: '/api/stat/operate/channel/export',
      method: 'post',
      data: {
        channel_id: data.channelId,
        store_id: data.storeId,
        start_date: data.startDate,
        end_date: data.endDate,
        page: data.page,
        limit: data.limit,
      },
      headers: {
        meta: {
          skipSuccess: true,
          desc: '导出',
        },
      },
    });
  }
}

export default OperationConditionApi;
