export const OPERATION_CONDITION_COLUMNS = [
  {
    title: '渠道名称/门店名称',
    key: 'channel_name',
    slot: 'channelOrStoreName',
    minWidth: 180,
  },
  {
    title: '门店数量/设备数量',
    key: 'store_ct',
    slot: 'StoreOrDeviceCount',
    minWidth: 180,
  },
  {
    title: '门店活跃量/设备活跃量',
    key: 'store_active_ct',
    slot: 'StoreOrDeviceActiveCount',
    width: 190,
  },
  {
    title: '总训练次数',
    key: 'train_ct',
    width: 120,
  },
  {
    title: '总检查次数',
    key: 'exam_ct',
    width: 120,
  },
  {
    title: '单店/单台平均训练次数',
    key: 'avg_product_train_ct',
    width: 160,
  },
  {
    title: '总分配次数',
    key: 'dist_freq_card',
    width: 120,
  },
  {
    title: '总使用次数',
    key: 'train_scan_ct',
    width: 120,
  },
];
